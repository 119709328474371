.notification-dropdown-container {
    position: relative;
  }
  
  .notification-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .notification-icon-container {
    position: relative;
  }
  
  .notification-item {
    transition: all 0.3s ease-in-out; /* Define the transition property */
    max-height: 500px;
     margin-bottom: 10px;
  }
  
  .notification-item.removed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
  }
  
  
  .notification-count {
    position: absolute;
    top: -8px;
    right: 0;
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .notification-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px; /* Adjust the width value as needed */
    height: 400px;
    overflow-y: auto;
  }
  
  
  .clicked {
    background-color: #d2c2f8; /* Change to desired color */
    transition: background-color 0.3s ease;
  }
  
  
  .no-notifications {
    color: #888;
  }
  
  .notification-dropdown-container:hover .notification-dropdown {
    display: block;
  }
  
  .notification-dropdown-container:hover .notification-icon {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  