 .EarningsCard {
height: 100%;
width: 14rem;
background-color: #00B4D8;
padding: 1rem;
border-radius:1rem;
color:white;
 }


transition: 0.4s ease-in-out;
&:hover{
    box-shadow:$(hoverEffect);
}
@media screen and (min-width:320px) and (max-width:1080px){
 width:80%;
}

 .CardContent {
margin:1rem;
 }


.Chart{
    justify-content: center;
    display: flex;
    
}
 .EarningsText {font-family:Poppins, Medium;
    text-align:center;
    font-weight:normal;
    font-family:Poppins;
    padding:0.4rem 0;
}


 .Earning {
text-align:center;
 }
.EarningsIncrease {
text-align:center;
font-family:Poppins, Medium;
font-weight:normal;
background-color:#48819A;
padding:0.5rem;
border-radius:2rem;
}

/* Info CSS */
.sidebar {
    width: 200px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 100;
  }
  
  .sidebar li {
    list-style: none;
    padding: 10px;
  }
  
  .sidebar li a {
    color: #333;
    text-decoration: none;
  }
  
  .sidebar li a:hover {
    color: #000;
  }
  .sidebar li a.active {
    color: #000;
    background-color: green;
  }
    