.Table{
    width:40rem;

}



.select-container{
    width: '10rem';
    height: '3rem';
    overflow: 'auto';
    margin: '0.5rem';
  
}


@media screen and (min-width: 280px) and (max-width: 1080px) {
    .select-container {
      width: 40vw;
      height: '3rem';
      overflow: 'auto';
    }
  }