/* Update these specific classes */

.kanban-board {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 80vw;
 
  margin-top: 15rem;
  overflow-y: hidden; /* Hide vertical scroll */
}

.scrollable-board {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Hide vertical scroll */
  padding: 1rem 0;
  margin: -1rem;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  position: relative;
  max-height: calc(100vh - 180px); /* Adjust this value based on your header size */
}

.task-list {
  max-height: calc(100vh - 220px); /* Slightly smaller than scrollable-board */
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid #90E0EF;
  border-radius: 8px;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.column {
  flex: 0 0 300px;
  margin: 0 10px;
  border-radius: 8px;
  padding: 10px;
  min-width: 300px;
  height: fit-content;
}

/* Update media query */
@media screen and (max-width: 1080px) {
  .kanban-board {
    max-width: 95vw;
    padding: 10px;
    overflow-y: hidden;
  }

  .scrollable-board {
    max-height: calc(100vh - 150px);
  }

  .task-list {
    max-height: calc(100vh - 190px);
  }
}
.board-controls-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 1rem;
}

.board-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.board-title {
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

.select-field {
  width: 15rem !important;
  min-width: 8rem !important;
}

.custom-select {
  width: 15rem !important;
  height: 38px;
  padding: 2px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

/* Labels for select fields */
.select-label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.25rem;
}

/* Add this to your existing kanban board CSS */
.kanban-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
}

/* Optional: Add a title style if needed */
.board-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.custom-width {
  width: 15rem;
}

.scrollable-board {
  display: flex;
  overflow-x: auto;
  padding: 1rem 0;
  margin: -1rem;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  position: relative;
}

/* Add scroll buttons */
.scroll-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.scroll-left {
  left: 10px;
}

.scroll-right {
  right: 10px;
}

.scroll-button:hover {
  background: #f5f5f5;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Track */
.scrollable-board::-webkit-scrollbar {
  height: 8px;
}

/* Handle */
.scrollable-board::-webkit-scrollbar-thumb {
  background: #90E0EF;
  border-radius: 4px;
}

/* Handle on hover */
.scrollable-board::-webkit-scrollbar-thumb:hover {
  background: #00B4D8;
}



.task-list {
  height: 60vh;
  overflow-y: auto;
  border: 2px solid #90E0EF;
  border-radius: 8px;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.task {
  margin: 8px 0;
  padding: 12px;
  background-color: white;
  border: 1px solid #90E0EF;
  border-radius: 6px;
  border-left: 6px solid #00B4D8;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.task:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.task-content {
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.task-content:hover {
  height: auto;
}

.task-title {
  font-weight: 500;
  margin-bottom: 8px;
}

.task-sprint {
  font-size: 12px;
  color: white;
  background-color: #ff6666;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px 0;
}

.task-sprint1 {
  font-size: 12px;
  color: white;
  background-color: rgb(129, 129, 3);
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px 0;
}

.task-sprint2 {
  font-size: 12px;
  color: white;
  background-color: green;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px 0;
}

@media screen and (max-width: 1080px) {
  .kanban-board {
    max-width: 95vw;
    padding: 10px;
  }

  .board-controls {
    flex-direction: column;
  }

  .custom-width {
    width: 100%;
  }

  .column {
    min-width: 280px;
  }
}

{/* CSS remains the same */}
.task-header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}

.task-type-icon {
  flex-shrink: 0;
}

.jira-icon {
  color: #0052CC;
}

.pod-icon {
  color: #36B37E;
}
